<template>
  <div>
    <b-modal
      id="modal-agent-add"
      ref="modal"
      v-model="open"
      size="lg"
      no-close-on-backdrop
      hide-footer
      :title="modalTitle"
    >
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createBranchForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  :label="`${type} Name`"
                  label-for="h-agentName"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Agent Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="`Enter ${type} Name Here!!`"
                      type="text"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Primary Address"
                  label-for="h-pAddress"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Primary Address"
                    rules="required"
                  >
                    <b-form-textarea
                      id="h-pAddress"
                      v-model="form.primary_address"
                      :state="errors.length > 0 ? false:null"
                      size="sm"
                      placeholder="Enter Address Here!"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="required"
                  label="Primary Email"
                  label-for="h-pEmail"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="h-pEmail"
                      v-model="form.primary_email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Email Here!!"
                      type="email"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Primary Contact"
                  label-for="h-pContact"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="Contact"
                  >
                    <b-form-input
                      id="h-pContact"
                      v-model="form.primary_contact"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Contact Here!!"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Logo"
                  label-for="h-log"
                  label-cols-md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="File Name"
                  >
                    <b-form-file
                      id="h-logo"
                      v-model="form.logo_url"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="type === 'Agent' && finance.commission_invoice.agency_commission_invoice.enabled"
                cols="12"
                class="mb-1"
              >
                <h4>
                  Agent Commission Invoice Setting
                </h4>
                <invoice-date-calendar
                  :selected-type.sync="selectedType"
                  :selected-day.sync="selectedDay"
                  :period.sync="period"
                  :applied-date.sync="appliedDate"
                />
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="States"
                  label-for="addNewtreeview"
                  label-cols-md="3"
                >
                  <div class="control_wrapper">
                    <ejs-treeview
                      id="treeview"
                      :fields="fields"
                      :node-checked="nodeChecked"
                      :checked-nodes="selectedCities"
                      :show-check-box="true"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                offset-md="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearOnClick"
                >
                  Clear
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import InvoiceDateCalendar from '@/components/InvoiceCalendar/InvoiceDateCalendar.vue'
import initializeTreeviewStyles from '@/libs/syncfusion/ej2-vue-navigations/'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const FinanceSettingsRepository = RepositoryFactory.get('financeSetting')

function initialState() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
  const day = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.

  const formattedDate = `${year}-${month}-${day}`

  return {
    open: false,
    id: null,
    modalPurpose: 'add',
    type: 'agent',
    loading: false,
    fields: [],
    selectedCities: [],
    selectedCitiesCache: [],
    firstExpansion: true,
    form: {},
    selectedType: 'Day',
    selectedDay: 'Mon',
    period: 1,
    appliedDate: formattedDate,
    finance: {
      commission_invoice: {
        agency_commission_invoice: {
          enabled: false,
        },
      },
    },
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BRow,
    BOverlay,
    InvoiceDateCalendar,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? `ADD NEW ${this.type.toString().toUpperCase()}` : `UPDATE ${this.type.toString().toUpperCase()}`
    },
  },
  methods: {
    async openModal(id, purpose, type) {
      await Object.assign(this.$data, initialState())
      this.loading = true
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      this.type = type
      await this.fetchDataDistrictList()
      if (this.modalPurpose === 'edit') {
        await this.fetchAgentDetails(this.id)
      }
      await this.readFinanceSetting()
      initializeTreeviewStyles()
      this.listenToExpansion()
      this.loading = false
    },
    async readFinanceSetting() {
      try {
        this.finance = localStorage.getItem('finance_setting') ? JSON.parse(localStorage.getItem('finance_setting')) : (await FinanceSettingsRepository.getFinanceSettingsResource()).data
        if (!localStorage.getItem('finance_setting')) localStorage.setItem('finance_setting', JSON.stringify(this.finance))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchDataDistrictList() {
      try {
        this.loading = true
        const { data } = (await ResourceRepository.getDistrictList()).data
        const dataSource = []
        data.forEach(ds => {
          ds.cities.forEach(city => {
            // eslint-disable-next-line no-param-reassign
            city.pid = ds.id
            // eslint-disable-next-line no-param-reassign
            city.expanded = false
            // eslint-disable-next-line no-param-reassign
            city.isChecked = false
            dataSource.push(city)
          })
          // eslint-disable-next-line no-param-reassign
          ds.expanded = false
          // eslint-disable-next-line no-param-reassign
          ds.isChecked = false
          // eslint-disable-next-line no-param-reassign
          ds.hasChild = true
          dataSource.push(ds)
        })
        this.fields = {
          dataSource,
          id: 'id',
          parentID: 'pid',
          text: 'name',
          hasChildren: 'hasChild',
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchAgentDetails(id) {
      this.loading = true
      try {
        let data
        if (this.type === 'Agent') {
          data = (await ResourceRepository.getAgencyDetails(id)).data.data
          this.period = data.warehouse_setting.invoice_period
          this.selectedType = data.warehouse_setting.invoicing_cycle
          this.selectedDay = data.warehouse_setting.day_of_week
          const [firstWord] = data.warehouse_setting.next_invoice_generate_date.split(' ')
          this.appliedDate = firstWord
        } else {
          data = (await ResourceRepository.getBranchDetails(id)).data.data
        }
        if (this.modalPurpose === 'edit') {
          delete data.logo_url
        }
        this.form = data
        this.selectedCities = this.form.cities.map(n => (n.id).toString())
        this.selectedCitiesCache = this.selectedCities
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleFormSubmit() {
      this.loading = true
      try {
        this.selectedCities = this.selectedCities.filter(n => !n.startsWith('S'))
        // Check if any previously assigned city is unassigned
        const unassignedCities = this.selectedCitiesCache.filter(city => !this.selectedCities.includes(city))
        if (unassignedCities.length > 0) {
          this.showErrorMessage('Cannot Unassign City From Warehouse')
          this.loading = false
          return
        }
        const data = {
          name: this.form.name,
          primary_address: this.form.primary_address,
          primary_contact: this.form.primary_contact,
          primary_email: this.form.primary_email,
          city_ids: this.selectedCities,
        }
        let res
        if (this.type === 'Agent') {
          data.invoice_period = this.period
          data.invoicing_cycle = this.selectedType
          data.next_invoice_generate_date = this.appliedDate
          if (this.selectedType === 'Week') {
            data.day_of_week = this.selectedDay
          }
          if (this.modalPurpose === 'add') {
            res = await ResourceRepository.createAgency(data)
          } else {
            res = await ResourceRepository.updateAgent(this.id, data)
          }
        } else if (this.type === 'Branch') {
          if (this.modalPurpose === 'add') {
            res = await ResourceRepository.createBranch(data)
          } else {
            res = await ResourceRepository.updateBranch(this.id, data)
          }
        }
        if (res.status === 200 || res.status === 201) {
          if (this.form.logo_url) {
            let logoRes
            if (this.type === 'Agent') {
              logoRes = await ResourceRepository.updateAgentLogo(res.data.data.id, this.form.logo_url)
            } else if (this.type === 'Branch') {
              logoRes = await ResourceRepository.updateBranchLogo(res.data.data.id, this.form.logo_url)
            }
            if (logoRes.status === 200) {
              this.open = false
              this.$parent.onClickRefresh()
              this.clearWarehouseCache()
              this.showSuccessMessage(`${this.type} ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
            } else {
              this.showErrorMessage(`Failed To Upload ${this.type} Logo`)
            }
          } else {
            this.open = false
            this.$parent.onClickRefresh()
            this.clearWarehouseCache()
            this.showSuccessMessage(`${this.type} ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
          }
        } else {
          this.showErrorMessage(`Cannot ${this.modalPurpose === 'add' ? 'Add' : 'Update'} ${this.type}`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    nodeChecked() {
      const addNewTreeObj = document.getElementById('treeview').ej2_instances[0]
      this.selectedCities = addNewTreeObj.checkedNodes
    },
    listenToExpansion() {
      if (this.modalPurpose === 'edit') {
        const elements = document.getElementsByClassName('e-icon-expandable')
        elements.forEach(element => {
          element.addEventListener('click', () => {
            if (this.firstExpansion) {
              const addNewTreeObj = document.getElementById('treeview').ej2_instances[0]
              addNewTreeObj.checkedNodes = this.selectedCitiesCache
              this.firstExpansion = false
            }
          })
        })
      }
    },
    clearOnClick() {
      this.selectedCities = []
      this.form = {}
    },
    clearWarehouseCache() {
      if (this.type === 'Agent') {
        localStorage.removeItem('agent_list')
      } else if (this.type === 'Branch') {
        localStorage.removeItem('branch_list')
      }
      localStorage.removeItem('warehouse_list')
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
