<template>
  <b-row>
    <b-col>
      <div>
        <b-form-group
          label="Invoice Date"
          label-for="invoice-date"
          class="dateFormGroup"
        />
        <b-col class="dateCol d-flex flex-wrap">
          <b-col
            sm="12"
            md="6"
            class="pr-md-0"
          >
            <b-row class="d-flex flex-wrap">
              <div class="d-flex mt-1 mb-1 flex-wrap align-items-center">
                <div class="d-flex align-items-center flex-grow-1">
                  <div
                    class="every"
                  >
                    Every
                  </div>
                  <div class="input-select-row">
                    <b-form-input
                      id="period"
                      v-model="period"
                      autocomplete="nope"
                      class="mr-1 inputField"
                      @input="updatePeriod"
                    />
                  </div>
                  <v-select
                    id="selectedType"
                    v-model="selectedType"
                    :options="dowOptions"
                    :reduce="option => option.name"
                    label="name"
                    class="mr-1 dropdown"
                    placeholder="Select option"
                    @input="updateSelectedType"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </div>
              </div>
            </b-row>
            <b-row v-if="selectedType === 'Week'">
              <div
                v-for="day in days"
                :key="day"
                class="day-column mb-1"
                :class="{ 'selected': day === selectedDay }"
                @click="selectDay(day)"
              >
                {{ day }}
              </div>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            md="3"
            class="mb-1 ml-md-5 ml-0 mt-1 calendar-alignment"
          >
            <b-calendar
              v-model="value"
              class="calendar"
              hide-header
              today-variant="primary"
              selected-variant="primary"
              locale="en"
              :date-info-fn="dateClass"
              :date-disabled-fn="dateDisabled"
              @context="onContext"
            />
          </b-col>
        </b-col>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  VBModal,
  BCalendar,
} from 'bootstrap-vue'

const currentDate = new Date()

const y = currentDate.getFullYear()
const m = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
const d = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.

const formattedDate = `${y}-${m}-${d}`

export default {
  directives: {
    'b-modal': VBModal,
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BCalendar,
  },
  props: {
    period: {
      type: Number,
      default: 0,
    },
    selectedDay: {
      type: String,
      default: '',
    },
    selectedType: {
      type: String,
      default: '',
    },
    appliedDate: {
      type: String,
      default: () => formattedDate,
    },
  },
  data() {
    return {
      dowOptions: [
        { name: 'Day' },
        { name: 'Week' },
      ],
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      value: '',
      count: 0,
      viewingMonth: currentDate.getMonth(),
      lastWeekCount: 0,
      todayYMD: formattedDate,
      dayInNumber: null,
    }
  },
  mounted() {
    this.setDayInNumber()
  },
  methods: {
    setDayInNumber() {
      if (this.selectedDay === 'Mon') {
        this.dayInNumber = 1
      } else if (this.selectedDay === 'Tue') {
        this.dayInNumber = 2
      } else if (this.selectedDay === 'Wed') {
        this.dayInNumber = 3
      } else if (this.selectedDay === 'Thu') {
        this.dayInNumber = 4
      } else if (this.selectedDay === 'Fri') {
        this.dayInNumber = 5
      } else if (this.selectedDay === 'Sat') {
        this.dayInNumber = 6
      } else if (this.selectedDay === 'Sun') {
        this.dayInNumber = 0
      }
    },
    updatePeriod() {
      this.$emit('update:period', this.period)
    },
    updateSelectedType() {
      this.$emit('update:selectedType', this.selectedType)
    },
    onContext(ctx) {
      if (ctx.selectedYMD === '' && this.appliedDate === null) {
        this.appliedDate = this.todayYMD
      }

      if (ctx.selectedYMD !== '' && this.appliedDate !== null) {
        this.appliedDate = ctx.selectedYMD
      }
      this.$emit('update:appliedDate', this.appliedDate)
    },
    selectDay(day) {
      if (day === this.selectedDay) {
        // Deselect the day if it's already selected
        this.selectedDay = ''
      } else {
        // Select the new day
        this.selectedDay = day
      }

      this.$emit('update:selectedDay', this.selectedDay)

      if (day === 'Mon') {
        this.dayInNumber = 1
      } else if (day === 'Tue') {
        this.dayInNumber = 2
      } else if (day === 'Wed') {
        this.dayInNumber = 3
      } else if (day === 'Thu') {
        this.dayInNumber = 4
      } else if (day === 'Fri') {
        this.dayInNumber = 5
      } else if (day === 'Sat') {
        this.dayInNumber = 6
      } else if (day === 'Sun') {
        this.dayInNumber = 0
      }
    },
    dateDisabled(ymd) {
      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.
      const today = `${currentYear}-${currentMonth}-${currentDay}`

      return ymd <= today
    },
    dateClass(ymd, date) {
      const day = date.getDay()
      const month = date.getMonth()

      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.
      const today = `${currentYear}-${currentMonth}-${currentDay}`

      if (this.period !== 0 && this.selectedType === 'Day') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.count
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.count = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.count = this.period
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.count < this.period) {
          this.count += 1
        }

        if (this.count / this.period === 1) {
          this.count = 0
          if (ymd >= today) {
            return 'table-info'
          }
        }
      }

      if (this.period !== 0 && this.dayInNumber !== null && this.selectedType === 'Week') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.weekCount
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.weekCount = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.weekCount = this.period - 1
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.weekCount < this.period && this.dayInNumber === day) {
          this.weekCount += 1
        }

        if (this.weekCount / this.period === 1 && this.dayInNumber === day) {
          this.weekCount = 0
          return 'table-info'
        }
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.dateFormGroup{
  height: fit-content;
  margin-bottom: 0;
}
.dateCol{
  border: 1px solid #bbbaba;
  border-radius: 5px;
}
.every{
  margin-right: 8px;
}
.inputField{
  width: 50px
}
.dropdown{
  width: 160px;
}
.day-column {
  flex: 1;
  text-align: center;
  font-weight:400;
  cursor: pointer;
  border: 2px solid rgba(107, 103, 151, 0.262);
}
.selected {
  background-color: #007bff; /* Change this to your desired selected day color */
  color: white; /* Change this to your desired text color for selected day */
}
.highlighted-date {
  background-color: #007bff;
  color: white;
}
@media (max-width: 768px) {
  .calendar-alignment {
    padding-left: 0;
  }
  .dateCol {
    overflow-x: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .calendar-alignment {
    padding-left: 15px;
  }
  .dateCol {
    flex-direction: column;
  }
  .calendar {
    align-self: center;
    width: 100%;
    margin-left: 0;
  }
  .day-column {
    display: inline-block;
    margin-right: 5px;
    padding: 5px;
  }

}
</style>
